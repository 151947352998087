html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  height: 100vh;
  width: 100%;
  font-size: 1.4rem;
  overflow-x: hidden;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  color: rgb(90, 90, 90);
}
#root {
  height: 100%;
}
h1 {
  opacity: 0;
  position: absolute;
  top: -200px;
  left: 0;
}
/* Layout */
.grid-container {
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'border'
    'footer';
  grid-template-columns: 1fr;
  grid-template-rows: 12rem 1fr 1px 12rem;
  height: 100%;
}

header {
  position: relative;
  width: 90vw;
  margin: 0 auto;
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
main {
  position: relative;
  width: 100vw;
  grid-area: main;
  margin: 0 auto;
  padding: 0 0 3rem 0;
}
.border {
  width: 100vw;
  grid-area: border;
  border-top: 1px solid rgba(90, 90, 90, 0.3);
}
.slide {
  width: 100%;
}

.slide img {
  width: 100vw;
  object-fit: cover;
}

footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 90vw;
  margin: 0 auto;
  padding: 1rem 0;
  grid-area: footer;
  color: rgb(90, 90, 90);
}

/* Common */
.header-logo {
  width: 100%;
  width: 25rem;
}
ul {
  padding: 0;
  list-style-type: none;
}
a {
  text-transform: uppercase;
  text-decoration: none;
  color: rgb(90, 90, 90);
}

a:hover {
  color: #ff8000;
}
li {
  text-transform: uppercase;
  list-style: none;
  padding-bottom: 0.3rem;
}
input {
  outline: none;
}
/* Header */
header a {
  color: rgb(90, 90, 90);
  padding: 1rem;
}
sup {
  font-size: 0.7rem;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.row.center {
  width: 90vw;
  margin: 0 auto;
  justify-content: center;
}
/* Image */
img {
  z-index: 1;
  display: block;
  margin: 0 auto;
}
.wrapper-img {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 30.9rem;
  height: 27rem;
  background-color: rgba(90,90,90,.1);
  border: 1px solid rgba(90,90,90,.1);
}
img.medium {
  display: block;
  max-width: 35rem;
  width: 100%;
  transform: scale(1);
  transition: all .7s ease-in;
}
img.medium:hover {
  transform: scale(1.2);
  transition: all .7s ease-in;
}
/*оформить новую коллекцию со скидкой*/
.action-new {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4.5rem;
  left: 0;
  right: 0;
  z-index: 4;
  background: rgba(255,255,255,1);
  color: #959595;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Card */
.card {
  border-radius: 0.5rem;
  margin: 1rem;
}

.card-body {
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  padding: 1rem;
  color: #959595;
}

.card-body>a>h2 {
  color: #959595;
  font-size: 1.4rem;
  font-weight: normal;
}
.button {
  cursor: pointer;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin: 0 auto;
  width: 70%;
  padding: 5px;
  color: #959595;
  border: 1px solid #959595;
  transition: all 1.5s ease;
  background-color: #fff;
}
.button:hover {
  border: 1px solid #ec6928;
  transition: all .5s ease;
}
.card-body>* {
  margin-bottom: 0.5rem;
}

.new-price {
  color: #ec6928;
}

.copyright {
  flex: 1 0 60%;
  font-size: 1.2rem;
}
.footer-right {
  font-size: 1.2rem; 
}

.icons-wrapper {
  display: flex;
  min-width: 10vw;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1rem;
}

.icon {
  display: block;
  max-width: 2.5rem;
  transform: scale(1);
  transition: all .3 ease-in;
}
.icon:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all .3 ease-in;
}
/*Product Screen*/
.product {
  width: 70vw;
  margin: 0 auto;
}
.row-top {
  margin: 5rem 0;
  align-items: flex-start;
  justify-content: center;
}
.product-link {
  display: block;
}
.product-link > button {
  text-transform: uppercase;
  border: 1px solid rgba(90, 90, 90, 0.3);
  background-color: #fff;
  color:rgb(90, 90, 90);
  padding: 0.5rem 2rem;
  transition: all .3 ease-in;
}
.product-link button:hover {
  cursor: pointer;
  border: 1px solid #ec6928;
  color: #ec6928;
  transition: all .3 ease-in;
}
.product-image {
  flex: 1 1 50%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(90,90,90,.2);
}
.product-image img {
  width: 100%;
  transform: scale(1);
  transition: all .7s ease-in; 
  display: block;
}
/*.product-image img:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: all .7s ease-in; 
}
*/
.product-desc {
  padding-top: 1rem;
}
.product-desc li {
  list-style: none;
  margin-bottom: 5vh;
}
.product-cart {
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-desc {
  color:rgb(90, 90, 90);
  flex: 0 1 80%;
}
.product-button {
  display: block;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #ec6928;
  border: 1px solid #ec6928;
  color: #fff;
  text-transform: uppercase;
}
select {
  background-color: rgb(255,255,255);
  border: 1px solid rgb(90, 90, 90);
}
select:active,
select:focus {
  outline: none
}
.zoom {
  cursor: pointer;
  position: absolute;
  top: 1rem; 
  right: 0;
  z-index: 1;
  width: 3rem;
  transform: scale(1);
  transition: all 0.7s ease-in;
}
.zoom > img {
  width: 100%;
}
.zoom:hover {
  transform: scale(1.06);
  transition: all 0.7s ease-in;
}
.show-image {
  z-index: 3;
  backdrop-filter: blur(30px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.show-img-block {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;

}
.show-img-block > img {
  width: 100%;
}
.hide-image {
  display: none;
}
/*LoadingBox*/
.cs-loader {
  transition: all 2s ease-in;
   position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cs-loader-inner {
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  width: calc(100% - 200px);
  color: #ec6928;
  padding: 0 100px;
  text-align: center;
}

.cs-loader-inner label {
  font-size: 20px;
  opacity: 0;
  display: inline-block;
}

@keyframes lol {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  33% {
    opacity: 1;
    transform: translateX(0px);
  }

  66% {
    opacity: 1;
    transform: translateX(0px);
  }

  100% {
    opacity: 0;
    transform: translateX(300px);
  }
}

@-webkit-keyframes lol {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-300px);
  }

  33% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }

  66% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(300px);
  }
}

.cs-loader-inner label:nth-child(6) {
  -webkit-animation: lol 3s infinite ease-in-out;
  animation: lol 3s infinite ease-in-out;
}

.cs-loader-inner label:nth-child(5) {
  -webkit-animation: lol 3s 100ms infinite ease-in-out;
  animation: lol 3s 100ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(4) {
  -webkit-animation: lol 3s 200ms infinite ease-in-out;
  animation: lol 3s 200ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(3) {
  -webkit-animation: lol 3s 300ms infinite ease-in-out;
  animation: lol 3s 300ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(2) {
  -webkit-animation: lol 3s 400ms infinite ease-in-out;
  animation: lol 3s 400ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(1) {
  -webkit-animation: lol 3s 500ms infinite ease-in-out;
  animation: lol 3s 500ms infinite ease-in-out;
}
/*MessageBox*/
.message-error {
  text-align: center;
  font-size: 1.8rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color:#a12c2c;
}
.update-success {
  color: #43b32d;
}
.cart-call {
  padding: 0 0 2rem 0;
}
/*cart-length (display count cartItems)*/
.cart-length {
   background-color: #ec6928;
   color: #ffffff;
   border-radius: 50%;
   padding: 0.2rem 0.7rem;
   font-size: 1.4rem;
   margin-left: 0.2rem;
}
/*CartScreen*/
.cart-wrapper,
.history, .placeorder {
  padding-top: 5rem;
  width: 70vw;
  margin: 0 auto;
}
.cart-wrapper > h2 {
  color: rgb(90, 90, 90);
}
.cart-cover {
  padding-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  color: rgb(90, 90, 90);
}
.cart-pickup {
  flex: 1 1 70%;
}
.mini-img {
  width: 10vw;
  flex: 0 1 15%;
}
.middle-img {
  width: 15vw;
  flex: 0 1 15%;
}
.cart-wrapper-recomendation {
  min-height: 5rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.cart-pickup-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.cart-pickup-desc,
.cart-pickup-remove,
.cart-pickup-price
 {
  flex: 0 1 20%;
}
.order-pickup-sum {
text-align: center;
}
.order-pickup-total {
  color: #ec6928;
}
.cart-pickup-remove {
  width: 30px;
  height: 30px;
}
.cart-pickup-remove > span {
  cursor: pointer;
 display: block;
 width: 30px;
 height: 30px;
}
.cart-pickup-remove>span::before {
  margin-top: 15px;
 content: '';
 display: block;
 width: 14px;
 height: 1px;
 background:rgb(90, 90, 90);
 transform: rotate(45deg);
}
.cart-pickup-remove>span::after {
  margin-top: -1px;
  content: '';
  display: block;
  width: 14px;
  height: 1px;
  background: rgb(90, 90, 90);
  transform: rotate(-45deg);
}
.order-pickup-btn {
  background: #fff;
}
.order-pickup-btn:hover {
  cursor: pointer;
  color: #ec6928;
}
.order-desc {
font-size: 1.6rem;
}
/*SigninScreen*/
/* Form */
.form {
  max-width: 60rem;
  margin: 0 auto;
}

.form>div {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.form label {
  margin: 1rem 0;
}
/* Dropdown */
.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 18rem;
  padding: 1rem;
  z-index: 5;
  background-color: #fff;
  margin: 0;
  margin-top: 0.7rem;
  border-radius: 0.5rem;
  text-align: center;
}
.dropdown-content > li {
  padding-bottom: 0.7rem;
}

.dropdown:hover .dropdown-content {
  display: block;
}
/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
  display: block;
  overflow: auto;
  overflow-wrap: break-word;
}
.table-noblock {
  display: table;
}
.table tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}
.table td,
.table th {
  text-align: left;
  border: 0.1rem solid #e4e4e4;
  padding: 0.5rem;
}
.table button {
  margin: 0 0.2rem;
}
/*Company*/
.title {
  color: #ec6928; 
  font-size: 2.2rem;
}
.large {
  font-size: 1rem;
}
.placeorder {
  display: flex;
  flex-direction: column;
}
.total {
  font-size: 1.6rem;
  padding: 3rem 0 2rem 0;
}
.button-order {
  margin: 0;
  padding: 1rem 0;
  max-width: 30rem;
  background-color: #ec6928;
  border: 1px solid #ec6928;
  color: #fff;
}

/*filter*/
.form-filter {
  width: 90vw;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
}
.category, .prices, .sizes, .btn-filter-reset, .btn-filter-submit {
  margin-left: 1rem;
  padding: 0.5rem 0.5rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  color:rgb(90, 90, 90);
  border: 1px solid rgba(90, 90, 90, 0.3);
  background-color: #fff;
}
.category:hover, .prices:hover, .sizes:hover {
cursor: pointer;
}
.btn-filter-reset, .btn-filter-submit, .prices, .sizes {
width: 17rem;
}
.btn-filter-reset:hover, .btn-filter-submit:hover {
cursor: pointer;
color: #ec6928;
border: 1px solid #ec6928;
}
.btn-mini {
  width: 10rem;
  font-size: 1rem;
}

/*Admin*/
.admin-list {
  width: 90vw;
  margin: 0 auto;
  border-top: 3px solid #ec6928;
  border-bottom: 3px solid #ec6928;
}
.admin-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  min-height: 50vh;
}
.admin-form >div>label {
  display: block;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.admin-form >div>input {
  width: 40vw;
}
.admin-btn {
  margin-top: 2rem;
  width: 40.5vw;
}

.link-style {
  display: inline-block;
  overflow: hidden;
  margin: 1.5rem;
}
.link-style > .medium {
  width: 100%;
  transform: scale(1);
  transition: all .7s ease-in; 
  display: block;
}
.link-style > .medium:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: all .7s ease-in;
}

/*Burger*/
.burger {
  opacity: 0;
  transform: translate(0, -100px);
  height: 2rem;
  position: absolute;
  top: 3.6rem;
  left: 2rem;
}
.burger-line {
  position: absolute;
  left: 2rem;
  top: 4.4rem;
}
.line {
  display: block;
  width: 3rem;
  height: 2px;
  border-bottom: 2px solid rgb(90, 90, 90);
  margin: 0 0 2px 0;
}
.burger:hover {
  cursor: pointer;
}

.delete {
  display: none;
}
.rotate-1 {
transform: rotate(45deg);
margin: -2px;
}
.rotate-2 {
  transform: rotate(-45deg);
  margin: -4px;
}
.cart-img {
  display: inline-block;
  width: 2rem;
}
.hide-cart {
z-index:5;
padding: 0;
display: none;
position: absolute;
top: 3.6rem;
left: 7rem;
}
.blur {
  position: relative;
}
.blur:after {
 content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(255,255,255,0.8); 
backdrop-filter: blur(2px);
}
.overflow {
  overflow: hidden;
}
.logo-wrap {
  z-index: 5;
}
@media(max-width: 800px) {
  .product-cart {
    padding-left: 0;
  }
  .show-img-block {
    width: 70%;
  }
}
@media(max-width: 680px) {
  .form-filter {
    width: 50vw;
    padding: 1rem;
  }
  .cart-wrapper {
    width: 90vw;
  }
  .header-logo {
    width: 20rem;
  }
  .middle-paragraph-recomendation {
    font-size: 1rem;
  }
  .middle-img {
    width: 25vw;
  }
}
@media(max-width: 620px){ 
  ul {
    padding: 0;
    margin: 0;
  }

  .add-padd {
    padding-top: 1rem;
  }
  .logo-wrap {
    z-index:5;
    position: absolute;
    top: 2.2rem;
    right: 2rem;
  }
  .burger {
    z-index:5;
    opacity: 1;
    transform: translate(0, 0);
  }
  .hide-cart {
    display: block;
    z-index: 3;
  }
  .cart-length {
    padding: 0.2rem 0.6rem;
    font-size: 1rem;
    margin-left: 0.2rem;
 }
  .table {
    font-size: 1.2rem;
  }
  header {
    width: 100%;
  }
  header a {
    display: block;
    font-size: 1.3rem;
    padding: 0.3rem
  }
  .navbar {
    padding-top: 9rem;
    height: 100vh;
    width: 70vw;
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
   display: flex;
   flex-direction: column;
   padding-left: 2rem;
   background: rgba(90,90,90,1);
  }
  .navbar a {
    color:rgba(255,255,255,1)
  }
 .dropdown-content {
   text-align: left;
   position: sticky;
   display: block;
  background: inherit;
  padding: 0;
}
.hide {
  opacity: 0;
  transform: translate(0, -1000px);
}
.show {
  opacity: 1;
  transform: translate(0, 0);
}
.white-line {
  border-bottom: 2px solid #fff;
}
.btn-hist {
  font-size: 1rem;
}
.show-img-block  {
  width: 100%;
}
}
@media(max-width: 590px) {
  footer {
    height: 19rem;
  }
}
@media(max-width: 584px) {
  .icons-wrapper {
    justify-content: flex-start;
  }
}
@media(max-width: 480px) {
  .history {
    width: 95vw;
  }
  .table {
    font-size: 1rem;
  }
  .btn-hist {
    font-size: .8rem;
  }
}
.policy {
  width: 80vw;
  margin: 0 auto;
}
.check-policy {
  width: 1.7rem;
  height: 1.7rem;
  cursor: pointer;
}

.category-admin {
  display: block;
  width: 45%;
  padding: 0.5rem;
  margin-top: 3rem;
}

.disabled {
  background-color: rgb(90,90,90);
  color:rgba(255,255,255,1);
  transition: all .5s ease;
}
.disabled:hover {
  border: 1px solid rgb(90,90,90);
  transition: all .5s ease;
}
@media(max-width: 360px) {
.header-logo {
  width: 17rem;
}
}
/*osano*/
.osano-cm-widget--position_right {
  opacity: 0;
  transform: translate(1000px,1000px);
}

/*Up button*/
.btn-scroll {
  cursor: pointer;
  z-index: 5;
position: fixed;
bottom: 9rem;
right: 3.5rem;
}
.btn-scroll-img {
  width: 3rem;
}
.hide-btn {
  display: none;
}
.show-btn {
  display: block;
}
/*Jivo*/
.cssLeaf_e164 {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2240%22%20viewBox%3D%220%200%2032%2040%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20fill%3D%22%235a5a5a%22%20d%3D%22M0%200h9.02L32%2033.196V40H0z%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20fill%3D%22%23ec6928%22%20d%3D%22M9%200c3.581.05%2023%205.426%2023%2033.08v.03C18.922%2030.751%209%2019.311%209%205.554V0z%22%2F%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A")!important;
}
.sendButton_339b {
background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2234%22%20height%3D%2234%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Ccircle%20fill%3D%22%23ec6928%22%20cx%3D%2217%22%20cy%3D%2217%22%20r%3D%2217%22%2F%3E%3Cg%20transform%3D%22translate(10%209)%22%20fill%3D%22%23FFF%22%3E%3Crect%20x%3D%226%22%20y%3D%222%22%20width%3D%222%22%20height%3D%2214%22%20rx%3D%221%22%2F%3E%3Crect%20transform%3D%22rotate(-45%209.879%204.879)%22%20x%3D%228.879%22%20y%3D%22-.121%22%20width%3D%222%22%20height%3D%2210%22%20rx%3D%221%22%2F%3E%3Crect%20transform%3D%22scale(-1%201)%20rotate(-45%200%2015.243)%22%20x%3D%223.293%22%20y%3D%22-.121%22%20width%3D%222%22%20height%3D%2210%22%20rx%3D%221%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A")!important;
}
.__client_bb2c ._green_0cfb {
background-color: #ec6928 !important;
}

.button_4963 {
  background-color: rgb(90,90,90)!important;
}
.main_a561.__green_8a7d {
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 8%), 0 0 1px 0 rgb(0 0 0 / 22%), inset 0 2px 0 0 #ec6928 !important;
}

.recomendationFilterList {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  background: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  padding: 1rem;
}
.recomendationFilter {
  display: flex;
  gap: 1rem;
}
.recomendation {
  min-height: 270px;
}